const { createContext } = require('react');

const PageContext = createContext({
  flexproEnabled: false,
  isSponsored: false,
  adsDisabled: false,
  membershipAvailable: false, // based on user cookie `bf-geo-country` - US or CA
});

export default PageContext;
